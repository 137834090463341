import React, { useMemo, useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

import { getCopy } from '../../../utils';
import { useLayout, usePageTitle } from '../../../hooks';
import {
    useProgressOverviewApi,
    useStudentApi,
    useTraineePlanningApi,
} from '../../../hooks/api';

import {
    useEnvironmentContext,
    useAppStateContext,
    useBreadcrumbTrailContext,
    useCoachingTraineeContext,
} from '../../../context';

import {
    Table,
    ScoreBadge,
    Button,
    FilterButton,
    Tag,
    StickyFooter,
    Popover,
    LinkList,
    SmallRatings,
    Icon,
    BlockBadge,
    AttendanceDisplay,
    ProgressImage,
    PageHeader,
    ProgressOverviewHeaderContent,
    WarningBar,
} from '../../../components/';

import STATUS from '../../../data/rating_statusses.json';

import styles from './Overview.module.scss';

const LEGACY_YEARS = (process.env.REACT_APP_FLEXX_LEGACY_YEARS || '').split(',');
const MAX_CRITERIA_SIZE = 9;

const ProgressOverview = ({ api, student, studentId, role, copyRole, planning, cohortYear }) => {
    const {
        screen: { isSmallDesktop },
        sidePanel: {
            open: openSidePanel,
            close: closeSidePanel,
            setContent: setSidePanelContent,
        },
    } = useAppStateContext();
    useLayout({ stretch: false, collapse: 'vertical', flexContent: true, margin: false });
    usePageTitle(`progress.overview.${role}`, { student: student?.full_name || '' });

    const {
        results,
        criteriaMap,
        selectedYear,
        setSelectedYear,
        selectedBlock,
        setSelectedBlock,
        toggleCourses,
        criteriaStartIndex,
        setCriteriaStartIndex,
        loading
    } = api;

    const showResultsColumn = results.some((result) => result?.hasResultsColumn);

    /* * * * * * * * * *
     * BREADCRUMBTRAIL *
    ** * * * * * * * * */
    const { put, remove } = useBreadcrumbTrailContext();
    useEffect(() => {
        const pageTitle = getCopy(`progress.${role}.overview.title`,
            { student: student?.full_name }
        );
        const path = getCopy(`progress.${role}.overview.path`, { studentId });

        put({
            name: pageTitle,
            href: path,
            loading,
        }, role === 'student' ? 1 : 2);

        return () => {
            remove(pageTitle);
        };
    }, [remove, put, role, studentId, student, loading]);

    /* * * * * * * * * * *
     * FILTER SIDEPANEL  *
    ** * * * * * * * * * */
    const { currentYear, upcomingYear, latestYear, blocks } = planning;
    const defaultYear = currentYear || upcomingYear || latestYear;

    const [allYears, allBlocks] = useMemo(() => {
        if (!blocks.length) {
            return [[], []];
        }

        return blocks.reduce(([ys, ps], { has_ratings, school_year: _y, school_year_id: _yId, number, period: _p, id: _pId }) => {
            if (!has_ratings) {
                return [ys, ps];
            }

            const yearIndex = ys.findIndex(({ yearId }) => yearId === _yId);
            const blockIndex = ps.findIndex(({ blockId }) => blockId === _pId);

            if (yearIndex <= -1) {
                ys.push({
                    yearLabel: _y,
                    yearId: _yId
                })
            }

            if (blockIndex <= -1) {
                ps.push({
                    number,
                    period: _p,
                    blockId: _pId,
                    year: _y
                })
            }

            return [ys, ps];
        }, [[], []]);
    }, [blocks]);

    useEffect(() => {
        setSidePanelContent(
            <FilterPanel
                allYears={allYears}
                allBlocks={allBlocks}
                defaultYear={defaultYear}
                selectedYear={selectedYear}
                setSelectedYear={setSelectedYear}
                selectedBlock={selectedBlock}
                setSelectedBlock={setSelectedBlock}
                closePanel={closeSidePanel}
            />
        );
    }, [allYears, defaultYear, selectedYear, selectedBlock, setSelectedYear, setSelectedBlock, setSidePanelContent, closeSidePanel, cohortYear, allBlocks]);

    const { filteredResults } = useMemo(() => {
        const filteredResults = results
            .filter(({ hasCourses }) => hasCourses);

            const resultsHasLegacyYears = filteredResults.some(({ courses }) => {
                return courses.filter(({ block: { school_year }}) => {
                    return LEGACY_YEARS.includes(school_year);
                }).length > 0;
            });

        return { filteredResults, resultsHasLegacyYears }
    }, [results]);

    /* * * * * * * *
     * TABLE ROWS  *
    ** * * * * * * */
    const headerRowMemo = useHeaderRowMemo(criteriaMap, copyRole, showResultsColumn, criteriaStartIndex, setCriteriaStartIndex);
    const subjectsRowsMemo = useBodyRowsMemo(filteredResults, criteriaMap, studentId, role, toggleCourses, showResultsColumn, criteriaStartIndex);

    /* * * * * * * * * * * * *
     * VALUES FOR IN FOOTER  *
    ** * * * * * * * * * * * */
    const cumulativeValues = useMemo(() => {
        const _acc = {
            total: 0,
            result: 0,
            [STATUS.COMPLETED]: 0,
            [STATUS.CLOSED]: 0,
            [STATUS.NOT_COMPLETED]: 0,
            [STATUS.BLANK]: 0,
            attendance: {
                total: 0,
                attended: 0,
            },
        };

        const values = filteredResults.reduce((acc, { courses }) => {
            courses.forEach(({ ratings, sessions, attendance }) => {
                const { status, published } = ratings || {};

                if (published) {
                    acc.total++;
                    acc[status]++;
                }

                acc.attendance.total += sessions;

                // attended should _never_ be more than the number of sessions
                acc.attendance.attended += Math.min(attendance, sessions);
            });

            return acc;
        }, _acc);

        const { total, attended } = values.attendance;

        // attendance percentage
        values.attendance = total > 0 ? parseInt(attended / total * 100, 10) : 100;

        values.result = parseInt(values[STATUS.COMPLETED] / (values.total - values[STATUS.CLOSED]) * 100, 10) || 0;

        return values;
    }, [filteredResults]);

    /* * * * * * * * * * * * * * *
     * STATES FOR MOBILE POPOVER *
    ** * * * * * * * * * * * * * */
    const [currentCourseId, setCurrentCourseId] = useState(false);
    const linkLists = useLinkListsMemo(filteredResults, setCurrentCourseId);

    const currentCourse = useMemo(() => {
        if (currentCourseId === false) {
            return undefined;
        }

        if (`${currentCourseId}`.substr(0, 3) === 'IT_') {
            const [groupIndex, courseIndex] = currentCourseId.substr(3).split('_');
            return results[parseInt(groupIndex, 10)].courses[parseInt(courseIndex, 10)];
        }

        const group = results.find(({ courses }) => courses.some(({ groupCourseId }) => groupCourseId === currentCourseId));
        const course = group.courses.find(({ groupCourseId }) => groupCourseId === currentCourseId);

        // we need to do some prop remapping to make sure <SmallRatings/> works
        const {
            criteria: _criteria,
            cleanName,
            block,
            ratings: {
                criteria: cRatings,
                feedback,
                status,
                result,
                result_type: resultType,
                attendance,
                published,
            },
            wasRepeated = false,
            nTry = 1,
        } = course;

        const nTryStars = wasRepeated ? '*'.repeat(nTry) : '';
        const popoverTitle = (
            <>
                {cleanName}{nTryStars}
                <BlockBadge block={block} className={styles['block-badge'] }/>
            </>
        );

        // remap criteria to work with <SmallRatings/> component
        const criteria = _criteria
            .reduce((acc, crit) => {
                const {
                    group_order: groupOrder,
                    code,
                    name,
                } = crit;

                crit.code = code;
                crit.name = name;

                const score = (cRatings || [])
                    .find(({ code: _cCode }) => _cCode === code)?.score;

                crit.score = score;

                if (acc[groupOrder]) {
                    acc[groupOrder].push(crit);
                } else {
                    acc[groupOrder] = [crit];
                }

                return acc;
            }, [])
            .filter(Boolean);

        return {
            ...course,
            popoverTitle,
            feedback,
            status,
            result,
            resultType,
            attendance,
            published,
            criteria,
        };
    }, [results, currentCourseId]);


    /* * * * * *
     * RENDER  *
    ** * * * * */
    if (loading) {
        return null;
    }

    const noResultsTitle = getCopy(`progress.${role}.overview.noResultsTitle`);
    const noResultsTagLine = getCopy(`progress.${role}.overview.noResultsTagLine`);
    const noResultsMessage = (
        <>
            <h3 className={styles['no-results']}>{noResultsTitle}</h3>
            <p>{noResultsTagLine}</p>
        </>
    );

    let filterButtonContent = 'Filter overzicht';
    if (selectedYear?.yearLabel) {
        filterButtonContent = selectedYear?.yearLabel;
    } else if (selectedBlock?.number) {
        filterButtonContent = `Blok ${selectedBlock?.number}`;
    }

    const studentCohort = (copyRole === 'teacher') ? (student?.cohort) : (cohortYear)
    const isFirstYear = studentCohort === currentYear;
    const firstYearMessage = getCopy(`rating.overview.firstYear.warning`);

    return (
        <>
            {isSmallDesktop ? (
                <>
                    <FilterButton
                        onClick={openSidePanel}
                        className={styles['filter-button']}
                    >
                        {filterButtonContent}
                    </FilterButton>
                    <div className={styles.container}>
                        {filteredResults.length ? (
                            <Table
                                rows={[ headerRowMemo, ...subjectsRowsMemo ]}
                                className={styles.table}
                            />
                        ) : noResultsMessage}
                        {
                            (isFirstYear) && (
                                <WarningBar icon="info" className={styles.warning}>
                                    {firstYearMessage}
                                </WarningBar>
                            )
                        }
                    </div>
                </>
            ) : (
                <>

                    <FilterButton
                        onClick={openSidePanel}
                        className={styles['filter-button']}
                    >
                        {filterButtonContent}
                    </FilterButton>
                    {filteredResults.length ? linkLists : noResultsMessage}
                    <Popover
                        mounted={currentCourseId !== false}
                        onClose={() => { setCurrentCourseId(false); }}
                        title={currentCourse?.popoverTitle}
                    >
                        {currentCourse !== undefined ? (
                            <SmallRatings
                                {...currentCourse}
                                role={copyRole}
                                studentId={studentId}
                                includePublicationStatus={copyRole === 'teacher'}
                                expanded
                            />
                        ) : null}
                    </Popover>
                    {
                        (isFirstYear) && (
                            <WarningBar icon="info" className={styles.warning}>
                                {firstYearMessage}
                            </WarningBar>
                        )
                    }
                </>
            )}

            {filteredResults.length ? (
                <StickyFooter>
                    <ul className={styles.footer}>
                        <li className={styles.footer__value}>
                            <span className={styles.footer__value__number}>
                                {`${cumulativeValues.total}`}
                            </span>
                            <span className={styles.footer__value__title}>
                                Totaal
                            </span>
                        </li>
                        <li className={styles.footer__value}>
                            <span className={styles.footer__value__number}>
                                {`${cumulativeValues[STATUS.COMPLETED]}`}
                            </span>
                            <span className={styles.footer__value__title}>
                                {STATUS.COMPLETED}
                            </span>
                        </li>
                        <li className={styles.footer__value}>
                            <span className={styles.footer__value__number}>
                                {`${cumulativeValues[STATUS.NOT_COMPLETED]}`}
                            </span>
                            <span className={styles.footer__value__title}>
                                {STATUS.NOT_COMPLETED}
                            </span>
                        </li>
                        <li className={styles.footer__value}>
                            <span className={styles.footer__value__number}>
                                {`${cumulativeValues[STATUS.CLOSED]}`}
                            </span>
                            <span className={styles.footer__value__title}>
                                {STATUS.CLOSED}
                            </span>
                        </li>
                        <li className={`${styles.footer__value} ${styles['footer__value--inverted']}`}>
                            <span className={styles.footer__value__number}>
                                {`${cumulativeValues.result}`}%
                            </span>
                            <span className={styles.footer__value__title}>
                                Resultaat
                            </span>
                        </li>
                    </ul>
                </StickyFooter>
            ) : null}

        </>
    )
};

/* * * * * * * * * * *
 * FOR COACH/MENTOR  *
** * * * * * * * * * */
const CoachProgressOverview = ({ role }) => {
    const { student, studentId, setLoading, planning } = useCoachingTraineeContext();
    const api = useProgressOverviewApi(studentId);

    const { loading } = api;
    useEffect(() => {
        setLoading(loading);
    }, [setLoading, loading]);

    return (
        <ProgressOverview
            api={api}
            student={student}
            studentId={studentId}
            copyRole="teacher"
            role={role}
            planning={planning}
        />
    );
};

/* * * * * * * *
 * FOR STUDENT *
** * * * * * * */
const StudentProgressOverview = () => {
    const { user, planning } = useEnvironmentContext();
    const { flexxId, cohort } = user;

    const api = useProgressOverviewApi();

    return (
        <ProgressOverview
            api={api}
            studentId={flexxId}
            copyRole="student"
            role="student"
            planning={planning}
            cohortYear={cohort}
        />
    );
};

/* * * * * * * *
 * FOR TEACHER *
** * * * * * * */
const TeacherProgressOverview = () => {
    const { student_id: studentId } = useParams();
    const {
        student,
        get: getStudent,
        loading: studentLoading,
        fetched: studentFetched,
    } = useStudentApi(studentId);

    const { planning } = useTraineePlanningApi(studentId, true);

    const api = useProgressOverviewApi(studentId);

    const fetchStudentWhen = studentId && !studentLoading && !studentFetched;
    useEffect(() => {
        if (fetchStudentWhen) {
            getStudent(studentId);
        }
    }, [fetchStudentWhen, getStudent, studentId]);

    const pageTitle = getCopy('progress.teacher.overview.pageTitle');

    return (
        <>
            <PageHeader title={pageTitle} collapsed>
                <ProgressOverviewHeaderContent studentId={studentId} />
            </PageHeader>
            <ProgressOverview
                api={api}
                student={student}
                studentId={studentId}
                copyRole="teacher"
                role="teacher"
                planning={planning}
            />
        </>
    );
}


/* * * * * *
 * EXPORT  *
** * * * * */
export {
    CoachProgressOverview,
    TeacherProgressOverview,
    StudentProgressOverview,
};

/* * * * * * * * * * *
 * FILTER COMPONENTS *
** * * * * * * * * * */
function FilterPanel ({
    allYears,
    allBlocks,
    defaultYear,
    selectedYear, setSelectedYear,
    selectedBlock, setSelectedBlock
}) {
    return (
        <>
            {allYears?.length ? (
                <>
                    <h2>Schooljaar</h2>
                    <ul className={styles['filter-list']}>
                        {allYears.map(({ yearId, yearLabel }) => (
                            <li
                                key={`filter-year-${yearId}`}
                                className={styles['filter-list__item']}
                            >
                                <button
                                    className={`${styles['filter-list__item__button']}${selectedYear?.yearId === yearId ? ' ' + styles['filter-list__item__button--current'] : ''}`}
                                    onClick={() => {
                                        setSelectedBlock(null);
                                        setSelectedYear({ yearLabel, yearId })
                                    }}
                                >
                                    {yearLabel}
                                    {defaultYear === yearLabel ? (
                                        <Icon
                                            type="calendar-today"
                                            size="small"
                                            color={selectedYear?.yearId === yearId  ? 'black' : 'blue-darker'}
                                            className={styles['filter-list__item__button__icon']}
                                        />
                                    ) : null}
                                </button>
                            </li>
                        ))}
                    </ul>
                </>
            ) : null}

            {allBlocks?.length ? (
                <>
                    <h2>Blokken</h2>
                    <ul className={styles['filter-list']}>
                        {allBlocks.map(({ number, period, blockId, year } = {}) => (
                            <li
                                key={`filter-number-${number}`}
                                className={styles['filter-list__item']}
                            >
                                <button
                                    className={`${styles['filter-list__item__button']}${selectedBlock?.blockId === blockId ? ' ' + styles['filter-list__item__button--current'] : ''}`}
                                    onClick={() => {
                                        setSelectedYear(null);
                                        setSelectedBlock({ number, period, blockId, year })
                                    }}
                                >
                                    {(number > 0) ? `Blok ${number}` : `${year} ${period}`}
                                </button>
                            </li>
                        ))}
                    </ul>
                </>
            ) : null}
        </>
    );
}

/* * * * * * * * * * *
 * MEMO'S FOR TABLE  *
** * * * * * * * * * */
const useHeaderRowMemo = (criteriaMap, copyRole, showResultsColumn, criteriaStartIndex, setCriteriaStartIndex,) => {
    return useMemo(() => {
        const nCriteria = criteriaMap.reduce((acc, criteriaGroup) => acc + criteriaGroup.length, 0);
        const renderSliderButtons = (nCriteria > MAX_CRITERIA_SIZE);

        const criteriaCells = criteriaMap.reduce((acc, group, gIndex, allGroups) => {
            const _cells = group.map(({ code, hidden }, ctIndex, allCtInGroup) => {
                const _bg = (renderSliderButtons) ?
                    ((criteriaStartIndex <= 0) && ((gIndex === 0) && (ctIndex === 0)) ? 'first' :
                    (criteriaStartIndex > 0) && ((gIndex === allGroups.length - 1) && (ctIndex === allCtInGroup.length - 1)) ? 'last' : true) :
                    allCtInGroup.length === 1 ? 'single' :                 // only crit in group
                            ctIndex === allCtInGroup.length - 1 ? 'last' : // last crit in group
                                !ctIndex && gIndex ? 'first' :             // first crit in group (not first)
                                    true                                   // all others;

                if (renderSliderButtons && hidden) {
                    return null;
                }

                 return ({
                    content: code,
                    align: 'center',
                    style: { width: '1.5em' },
                    spaceAfter: ctIndex === allCtInGroup.length - 1,       // last crit in group
                    bg: _bg,
                })
            });

            return [ ...acc, ..._cells ];
        }, []);

        if (renderSliderButtons && criteriaStartIndex > 0) {
            criteriaCells.splice(0, 0, {
                content:
                    <Button
                        icon={'chevron-left'}
                        variant="mini-circle"
                        iconSize="small"
                        onClick={() => setCriteriaStartIndex(0)}
                    />,
                align: 'center',
                style: { width: '1.5em' },
                bg: 'first',
                spaceAfter: true,
            })
        }

        if (renderSliderButtons && criteriaStartIndex <= 0) {
            criteriaCells.push({
                content:
                    <Button
                        icon={'chevron-right'}
                        variant="mini-circle"
                        iconSize="small"
                        onClick={() => setCriteriaStartIndex(1)}
                    />,
                align: 'center',
                style: { width: '1.5em' },
                spaceAfter: true,
                bg: 'last',
            })
        }

        criteriaCells.filter(Boolean)

        // header titles
        const coursesTitle = getCopy(`rating.tableHeaders.${copyRole}.courses.title`);
        const blockTitle = getCopy(`rating.tableHeaders.${copyRole}.block.title`);
        const resultTitle = getCopy(`rating.tableHeaders.${copyRole}.result.title`);
        const attendanceTitle = getCopy(`rating.tableHeaders.${copyRole}.attendance.title`);
        const feedbackTitle = getCopy(`rating.tableHeaders.${copyRole}.feedback.title`);
        const statusTitle = getCopy(`rating.tableHeaders.${copyRole}.status.title`);

        return {
            cells: [
                {
                    content: coursesTitle,
                    align: 'left',
                    sort: {
                        sortable: false,
                        direction: 'ASC',
                    },
                },
                {
                    content: blockTitle,
                    spaceBefore: true,
                    spaceAfter: true,
                    align: 'center',
                    style: { width: '5em' },
                    bg: (nCriteria > MAX_CRITERIA_SIZE) ? 'last' : true
                },
                ...criteriaCells,
                showResultsColumn && {
                    content: resultTitle,
                    align: 'center',
                    style: { width: '5em' },
                    bg: (nCriteria > MAX_CRITERIA_SIZE) ? 'first' : true
                },
                {
                    content: attendanceTitle,
                    align: 'center',
                    style: { width: '5em' },
                },
                {
                    content: feedbackTitle,
                    align: 'center',
                    style: { width: '5em' },
                },
                {
                    content: statusTitle,
                    align: 'center',
                    style: { width: '5em' },
                },
            ].filter(Boolean),
        }
    }, [criteriaMap, criteriaStartIndex, copyRole, showResultsColumn, setCriteriaStartIndex]);
};

const useBodyRowsMemo = (results, criteriaMap, studentId, role, toggleCourses, showResultsColumn, criteriaStartIndex) => {
    const { modal: { open: openModal } } = useAppStateContext();

    const nCriteria = criteriaMap.reduce((acc, criteriaGroup) => acc + criteriaGroup.length, 0);
    const renderSliderButtons = (nCriteria > MAX_CRITERIA_SIZE);

    return useMemo(() => {
        const rows = results.reduce((acc, { subject, expanded, showToggle, courses }) => {
            const subjectRow = {
                collapse: true,
                cells: [
                    {
                        content:
                            <div className={styles['toggle-row']}>
                                <h3 className={styles['subject-name']}>{subject}</h3>
                                { (showToggle) ? <Button
                                    icon={(expanded) ? 'toggle' : 'menu-hamburger'}
                                    variant="mini"
                                    iconSize="small"
                                    onClick={() => toggleCourses(subject)}
                                /> : '' }
                            </div>,
                        border: false,
                        align: 'left',
                        colSpan: '100%',
                    }
                ],
            };

            // sort courses by block number
            courses.sort(({ block: { number: a } = {} }, { block: { number: b } = {} }) => b < a ? -1 : a < b ? 1 : 0);

            const courseRows = (expanded) ? courses.map((course) => {
                const {
                    groupCourseId,
                    cleanName,
                    block: {
                        number: blockNumber,
                        school_year: schoolYear,
                    } = {},
                    sessions,
                    attendance,
                    ratings: {
                        criteria: criteriaRatings,
                        status,
                        published,
                        result = '',
                        feedback = '',
                    } = {},
                    wasRepeated = false,
                    nTry = 1,
                } = course;

                const criteriaCells = criteriaMap.reduce((acc, group) => {
                    const _cells = group.map(({ code: cCode, hidden }, ctIndex, allCtInGroup) => {
                        if (renderSliderButtons && hidden) {
                            return null;
                        }

                        const score = criteriaRatings
                            .find(({ code }) => code === cCode)?.score;

                        const content = score === undefined ? null : (
                            <ScoreBadge
                                className={styles['score-badge']}
                                value={score}
                            />
                        );

                        return {
                            content,
                            spaceAfter: ctIndex === allCtInGroup.length - 1, // last crit in group
                            align: 'center',
                        }
                    });

                    return [ ...acc, ..._cells ];
                }, []);

                const courseInfoPath = getCopy(
                    `progress.${role}.courses.linkTemplate`,
                    { studentId, groupCourseId }
                );

                const courseTitleClassNames = [styles['course-info-link']];
                if (wasRepeated) {
                    courseTitleClassNames.push(styles['course-info-link--faded']);
                }

                const nTryStars = wasRepeated ? '*'.repeat(nTry) : '';

                let courseTitleCellContent = (
                    <Link to={courseInfoPath} className={courseTitleClassNames.join(' ')}>
                        <strong>{cleanName}{nTryStars}</strong>
                    </Link>
                );

                if (!published) {
                    courseTitleCellContent = (
                        <>
                            <Icon type="eye-closed" color="red" valign="middle" inline />
                            {courseTitleCellContent}
                        </>
                    );
                }

                const blockNumberClassNames = [styles['block-number']];
                if (wasRepeated) {
                    blockNumberClassNames.push(styles['block-number--faded']);
                }

                const blockNumberCellContent = (
                    <span className={blockNumberClassNames.join(' ')}>
                        {blockNumber}
                    </span>
                );

                const tagValue = getCopy(`rating.status.${status}.name`);
                const tagColor = getCopy(`rating.status.${status}.tagColor`);

                return {
                    cells: [
                        {
                            content: courseTitleCellContent,
                            align: 'left',
                        },
                        {
                            content: blockNumberCellContent,
                            align: 'center',
                            spaceBefore: true,
                            spaceAfter: true,
                        },
                        (renderSliderButtons && criteriaStartIndex > 0) && {
                            content: "",
                            spaceAfter: true,
                            align: 'center',
                        },
                        ...criteriaCells,
                        (renderSliderButtons && criteriaStartIndex <= 0) && {

                            content: "",
                            spaceAfter: true,
                            align: 'center',
                        },
                        showResultsColumn && {
                            content: (
                                result
                            ),
                            align: 'center',
                        },
                        {
                            content: (
                                <AttendanceDisplay
                                    attendance={attendance}
                                    sessions={sessions}
                                    mode="view"
                                    year={schoolYear}
                                    studentId={studentId}
                                />
                            ),
                            align: 'center',
                        },
                        {
                            align: 'center',
                            content: (
                                <Button
                                    icon="edit"
                                    variant="mini"
                                    iconColor="blue"
                                    iconSize="large"
                                    onClick={() => openModal((feedback?.length) ? feedback : "Er is geen feedback gegeven.", 'Feedback')}
                                />
                            ),
                        },
                        {
                            align: 'center',
                            content: <Tag color={tagColor}>{tagValue}</Tag>,
                        }
                    ].filter(Boolean),
                };
            }) : [[{ content: <hr/>, border: false, colSpan: '100%', }]];

            return [ ...acc, subjectRow, ...courseRows ];
        }, []);

        return rows;
    }, [results, toggleCourses, criteriaMap, role, studentId, renderSliderButtons, criteriaStartIndex, showResultsColumn, openModal]);
};

/* * * * * * * * * * * * * * * * *
 * MEMO'S FOR LINKLISTS (MOBILE) *
** * * * * * * * * * * * * * * * */
const useLinkListsMemo = (results, setCurrentCourseId) => {
    return useMemo(() => {
        return results.map(({ subject, courses }) => (
            <React.Fragment key={`linklist-${subject}`}>
                <h3 className={styles['subject-name']}>{subject}</h3>
                <LinkList
                    links={courses.map(({
                        cleanName,
                        groupCourseId: id,
                        ratings: { status, published } = {},
                        wasRepeated = false,
                        nTry = 1,
                    }) => {
                        const courseTitleClassNames = [
                            styles['course-info-link'],
                            styles['course-info-link--no-hover']
                        ];

                        if (wasRepeated) {
                            courseTitleClassNames.push(styles['course-info-link--faded']);
                        }

                        const nTryStars = wasRepeated ? '*'.repeat(nTry) : '';

                        return {
                            children: (
                                <div className={styles['linklist-link-content']}>
                                    <strong>
                                        {!published && (
                                            <Icon type="eye-closed" color="red" inline />
                                        )}
                                        <span className={courseTitleClassNames.join(' ')}>
                                            {cleanName}{nTryStars}
                                        </span>
                                    </strong>
                                    <ProgressImage className={styles['progress-image']} status={status} />
                                </div>
                            ),
                            onClick: () => {
                                setCurrentCourseId(id);
                            }
                        };
                    })}
                    collapseTop
                />
            </React.Fragment>
        ));
    }, [results, setCurrentCourseId]);
};
