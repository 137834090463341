import React from "react";
import Bem from "react-better-bem";

import { Icon, Button } from "../../../components";

import { getCopy } from "../../../utils";

import styles from "./BPVBody.module.scss";

const BPVBody = ({ bpvCoursesCompleted = false, studentName, isStudent, bpvLinks }) => {
    const bodyTitle = getCopy(`bpv.body.title`, { studentName: !isStudent ? `${studentName ? studentName : ' '} heeft` : 'Je hebt' });
    const bodyContent = getCopy(`bpv.body.content`, { studentName });
    const firstButton = getCopy(`bpv.links.bodySmall`);
    const secondButton = getCopy(`bpv.links.bodyLarge`);
    const internshipTitle = getCopy(`bpv.body.startInternshipTitle`, { studentName: !isStudent ? studentName : 'Je', verb: !isStudent ? 'is' : 'bent' });

    const { learningGoalsLink, requestFormLink } = bpvLinks;
    const internshipContent = getCopy(`bpv.body.startInternshipContent.${(learningGoalsLink) ? 'referenceForm': 'minimal'}`);

    return (
        <Bem style={styles}>
            <article el="main" mod={{ party: bpvCoursesCompleted, noparty: !bpvCoursesCompleted }}>
                <div el="col">
                    <Icon
                        color="red"
                        type={bpvCoursesCompleted ? "party" : "owl-intern"}
                        size="extra-large"
                        className={styles["main--img"]}
                        internship
                    />
                </div>
                <div el="col" mod="content">
                    <h1
                        el="title"
                    >
                        {!bpvCoursesCompleted ? bodyTitle : internshipTitle}
                    </h1>
                    <p el="text">
                        <strong>
                            {!bpvCoursesCompleted ? bodyContent : internshipContent}
                        </strong>
                    </p>
                   {(isStudent && (requestFormLink || learningGoalsLink)) ? (
                        <div el="button-container">
                            {requestFormLink && (
                                <Button
                                    variant="inverted"
                                    href={requestFormLink}
                                    className={styles["main__col__button-container--btn"]}
                                    disabled={requestFormLink && !isStudent}
                                >
                                    {firstButton || " Stage aanvraagformulier"}
                                </Button>
                            )}
                            {learningGoalsLink && (
                                <Button
                                    variant="default"
                                    href={learningGoalsLink}
                                    disabled={learningGoalsLink && !isStudent}
                                    download="Leedoelenformulier.docx"
                                >
                                    {secondButton || "Leerdoelen formulier downloaden"}
                                </Button>
                            )}
                        </div>) : null
                   }
                </div>
            </article>
        </Bem>
    );
};

export default BPVBody;
